import { graphql } from "../graphql";
import query from "./query.gql";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";

export async function fetchDiscounts(
  businessId: string,
  resultsPerBatch: number,
  batch: number,
  searchTerm?: string
): Promise<Response> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.query({
      query,
      variables: {
        businessId,
        search: searchTerm,
        perPage: resultsPerBatch,
        page: batch,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new FetchDiscountsError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new FetchDiscountsError(errors);
  }

  return {
    discounts: response.data.discounts.data,
    paginatorInfo: response.data.discounts.paginatorInfo,
  };
}

export class FetchDiscountsError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: FetchDiscounts");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};

interface Response {
  paginatorInfo: {
    count: number;
    currentPage: number;
    lastPage: number;
    perPage: number;
    total: number;
  };
  discounts: Array<{
    id: string;
    name: string;
    description: string;
    code: string;
    visibility: string;
    type: string;
    amount: string;
    minimumOrderValue: string;
    maximumDiscount: string;
    segmentation: string;
    usesPerCustomer: string;
    startsAt: string;
    endsAt: string;
    totalUses: string;
    status: boolean;
    totalSales: string;
    totalUsage: string;
    orderingServices: string;
  }>;
}
