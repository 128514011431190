import { EventEnum } from "@/v2/enum/events";
import { reactivationEmailBusinessId } from "../state";

/**
 * TODO: I really did not want to write this function
 * but unfortunately its here till I figure out how to
 * stop relying on VueX or atleast isolate it's use like
 * this example
 **/
export function reactivationEmailMethodTrack(
  context: any,
  eventName: EventEnum,
  additionalData: Record<string, any> = {}
): void {
  const userData = context.root.$store.getters["auth/getData"];
  const businessesData = context.root.$store.getters["businesses/getData"];
  const currentBusiness = businessesData.find(
    (business: any) => business.id === reactivationEmailBusinessId.value
  );

  // @ts-ignore
  context.root.$analytics.trackEvent(eventName, {
    businessId: currentBusiness.id,
    businessName: currentBusiness.name,
    id: userData.id,
    roles: userData.roles,
    ...additionalData,
  });
}
