import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorValidationEnum } from "../repo-error.enum";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";
import { ReactivationFrequencyDaysEnum } from "@/v2/enum/reactivation/frequency-days.enum";
import { ReactivationPeriodOfDayEnum } from "@/v2/enum/reactivation/period-of-day.enum";

export interface IUpdateReactivationCampaignSettingRequest {
  businessId: string;
  campaignSettings: {
    frequencyDays: ReactivationFrequencyDaysEnum;
    periodOfDay: ReactivationPeriodOfDayEnum;
    discountId: string | null;
  };
}

export async function updateReactivationCampaignSettings(
  request: IUpdateReactivationCampaignSettingRequest
): Promise<Response> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        businessId: request.businessId,
        campaignSettingsAttributes: request.campaignSettings,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new UpdateReactivationCampaignSettingError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response, [
      {
        ifKey: "campaignSettingsAttributes.frequencyDays",
        hasValue: "The frequency days must be greater than 0.",
        set: "frequencyDays",
        to: RepoErrorValidationEnum.MUST_BE_GREATER_THAN_ZERO,
      },
    ]);

    throw new UpdateReactivationCampaignSettingError(errors);
  }

  return response.data;
}

export class UpdateReactivationCampaignSettingError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: UpdateReactivationCampaignSetting");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {
  frequencyDays?: RepoErrorValidationEnum;
};

interface Response {
  updateCampaignSettings: boolean | null;
}
