














































































































































import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomRadio,
  AtomText,
  AtomTextSizeEnum,
  IMolAutoCompleteDropdownOption,
  MolAutocompleteDropdown,
  MolFormControl,
} from "@/v2/design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import { IReactivationEmailSettingFormProps } from "./props";
import { t } from "@/i18n";
import {
  reactivationEmailBusinessId,
  reactivationEmailIsDiscountValidState,
  reactivationEmailIsNewBusinessState,
  reactivationEmailLoadingCampaignState,
  reactivationEmailModalShowState,
  reactivationEmailSettingsState,
} from "../state";
import { ReactivationPeriodOfDayEnum } from "@/v2/enum/reactivation/period-of-day.enum";
import { fetchDiscounts } from "@/v2/repo/fetch-discounts";
import {
  reactivationEmailMethodSaveCampaignActiveState,
  reactivationEmailMethodSaveCampaignSettings,
} from "../method";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { reactivationEmailMethodIsDiscountValid } from "../method/is-discount-valid";
import { fetchDiscount } from "@/v2/repo/fetch-discount";
import { reactivationEmailSetOfferContent } from "../method/set-offer-content";
import { reactivationEmailMethodTrack } from "../method/track";
import { EventEnum } from "@/v2/enum/events";

const css = bemBuilder("reactivation-email-setting-form");

export default defineComponent<IReactivationEmailSettingFormProps>({
  name: "ReactivationEmailSettingForm",
  components: {
    AtomText,
    AtomRadio,
    AtomButton,
    MolAutocompleteDropdown,
    MolFormControl,
  },
  setup(props, ctx) {
    const discountOptionsState = ref<Array<IMolAutoCompleteDropdownOption>>([]);

    const modalShowState = reactivationEmailModalShowState;
    const settingsState = reactivationEmailSettingsState;
    const isNewBusinessState = reactivationEmailIsNewBusinessState;
    const submitLoadingState = ref(false);

    async function onChangeDiscount(
      selectedDiscount: IMolAutoCompleteDropdownOption
    ) {
      if (selectedDiscount) {
        settingsState.discountId = selectedDiscount?.value || null;
        settingsState.selectedDiscountLabel = selectedDiscount?.label;
        const discount = await fetchDiscount(
          reactivationEmailBusinessId.value,
          selectedDiscount?.value
        );
        reactivationEmailSetOfferContent(discount);
        reactivationEmailIsDiscountValidState.value =
          reactivationEmailMethodIsDiscountValid(
            discount.status,
            discount.endsAt
          );
      } else {
        settingsState.discountId = null;
        settingsState.selectedDiscountLabel = "";
        reactivationEmailIsDiscountValidState.value = true;
        reactivationEmailSetOfferContent(null);
      }
    }

    function onClickCustomizeEmail() {
      modalShowState.value = true;

      reactivationEmailMethodTrack(ctx, EventEnum.CLICKED_ON_BUTTON, {
        label: "Customize email",
      });
    }

    function onClickCreateDiscount() {
      reactivationEmailMethodTrack(ctx, EventEnum.CLICKED_ON_BUTTON, {
        label: "Create new discount",
      });

      const route = ctx.root.$router.resolve({
        name: "discounts.create",
        params: {
          businessId: reactivationEmailBusinessId.value,
        },
      });

      window.open(route.href, "_blank", "noopener, noreferrer");
    }

    onMounted(() => {
      loadDiscounts("");
    });

    async function onChangeDiscountQuery(searchTerm: string) {
      loadDiscounts(searchTerm);
    }

    async function loadDiscounts(searchTerm: string) {
      try {
        const discounts = await fetchDiscounts(
          reactivationEmailBusinessId.value,
          10,
          1,
          searchTerm
        );

        const discountOptions: Array<IMolAutoCompleteDropdownOption> = [];

        discounts.discounts.forEach((discount): void => {
          if (
            reactivationEmailMethodIsDiscountValid(
              discount.status,
              discount.endsAt
            )
          ) {
            discountOptions.push({
              value: discount.id,
              label: `${discount.code} · ${discount.name}`,
            });
          }
        });

        discountOptionsState.value = discountOptions;
      } catch (error) {
        generalErrorToast();
      }
    }

    async function onClickEnableReactivation() {
      submitLoadingState.value = true;
      await reactivationEmailMethodSaveCampaignSettings();
      await reactivationEmailMethodSaveCampaignActiveState(true);
      submitLoadingState.value = false;
    }

    watch(
      [
        () => settingsState.frequency,
        () => settingsState.sendingTime,
        () => settingsState.discountId,
      ],
      async () => {
        if (!reactivationEmailLoadingCampaignState.value) {
          await reactivationEmailMethodSaveCampaignSettings();
        }
      }
    );

    return {
      t,
      css,
      modalShowState,
      settingsState,
      discountOptionsState,
      isNewBusinessState,
      submitLoadingState,
      reactivationEmailIsDiscountValidState,
      onChangeDiscount,
      onChangeDiscountQuery,
      onClickCreateDiscount,
      onClickEnableReactivation,
      onClickCustomizeEmail,
      ReactivationPeriodOfDayEnum,
      AtomTextSizeEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
