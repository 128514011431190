import { Toast } from "@/design-system";
import { updateReactivationCampaignStatus } from "@/v2/repo/update-reactivation-campaign-status";
import {
  reactivationEmailBusinessId,
  reactivationEmailLoadingCampaignState,
  reactivationEmailToggleState,
} from "../state";
import { t } from "@/i18n";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { reactivationEmailMethodLoadCampaign } from "./load-campaign";

export async function reactivationEmailMethodSaveCampaignActiveState(
  isActive: boolean
): Promise<void> {
  try {
    const response = await updateReactivationCampaignStatus({
      businessId: reactivationEmailBusinessId.value,
      isActive,
    });

    if (response.updateCampaignStatus) {
      if (reactivationEmailToggleState.value) {
        new Toast().create({
          type: "success",
          title: t("c.reactivation.toast.toggle_on_title"),
          text: t("c.reactivation.toast.toggle_on_desc"),
        });

        reactivationEmailLoadingCampaignState.value = true;
        await reactivationEmailMethodLoadCampaign(
          reactivationEmailBusinessId.value
        );
        reactivationEmailLoadingCampaignState.value = false;
      } else {
        new Toast().create({
          type: "success",
          title: t("c.reactivation.toast.toggle_off_title"),
          text: t("c.reactivation.toast.toggle_off_desc"),
        });
      }
    }
  } catch (error) {
    generalErrorToast();
  }
}
