export function replaceReactiveObject(
  reactiveObject: Record<string, any>,
  replaceWith: Record<string, any>
): void {
  const valid =
    reactiveObject &&
    replaceWith &&
    !Array.isArray(reactiveObject) &&
    !Array.isArray(replaceWith);
  typeof reactiveObject === "object" && typeof replaceWith === "object";

  if (valid) {
    Object.keys(reactiveObject).forEach(
      (key) => (reactiveObject[key] = undefined)
    );
    Object.entries(replaceWith).forEach(
      ([key, value]) => (reactiveObject[key] = value)
    );
  } else {
    throw Error("replaceReactiveArray: Both arguments should be an object");
  }
}
