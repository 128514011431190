import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";

export interface IUpdateReactivationCampaignStatusRequest {
  businessId: string;
  isActive: boolean;
}

export async function updateReactivationCampaignStatus(
  request: IUpdateReactivationCampaignStatusRequest
): Promise<Response> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors = {};

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        businessId: request.businessId,
        isActive: request.isActive,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new UpdateReactivationCampaignStatusError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new UpdateReactivationCampaignStatusError(errors);
  }

  return response.data;
}

export class UpdateReactivationCampaignStatusError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateReactivationCampaignStatus");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

interface Response {
  updateCampaignStatus: boolean | null;
}
