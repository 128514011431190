import { Toast } from "@/design-system";
import { updateReactivationEmailTemplate } from "@/v2/repo/update-reactivation-email-template";
import {
  reactivationEmailBusinessId,
  reactivationEmailModalShowState,
  reactivationEmailState,
} from "../state";
import { t } from "@/i18n";
import { replaceReactiveObject } from "@/v2/util/replace-reactive-object";
import { generalErrorToast } from "@/v2/util/general-error-toast";

export async function reactivationEmailMethodSaveEmailTempalate(
  emailTemplate: typeof reactivationEmailState
): Promise<void> {
  try {
    const response = await updateReactivationEmailTemplate({
      businessId: reactivationEmailBusinessId.value,
      templateSettings: {
        coverImage: emailTemplate.previewImageURL,
        emailSubject: emailTemplate.subject || "",
        messageHeadline: emailTemplate.headline || "",
        messageBody: emailTemplate.content || "",
        offerHeadline: emailTemplate.offerHeadline,
        offerBody: emailTemplate.offerContent,
        buttonText: emailTemplate.buttonText || "",
      },
    });

    if (response.updateCampaignEmailTemplate) {
      new Toast().create({
        type: "success",
        title: t("c.reactivation.toast.email_updated_title"),
        text: t("c.reactivation.toast.email_updated_desc"),
      });

      reactivationEmailModalShowState.value = false;
      replaceReactiveObject(reactivationEmailState, emailTemplate);
    }
  } catch (error) {
    generalErrorToast();
  }
}
