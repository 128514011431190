import { Toast } from "@/design-system";
import {
  createReactivationEmailCoverImage,
  CreateReactivationEmailCoverImageError,
} from "@/v2/repo/create-reactivation-email-cover-image";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { reactivationEmailBusinessId } from "../state";
import { t } from "@/i18n";

export async function reactivationEmailCreateEmailCoverImage(
  image: File
): Promise<string | null> {
  try {
    const response = await createReactivationEmailCoverImage({
      businessId: reactivationEmailBusinessId.value,
      campaignCoverImage: image,
    });

    return response.uploadCampaignEmailCoverImage;
  } catch (error) {
    if (error instanceof CreateReactivationEmailCoverImageError) {
      if (error.errors.networkError || error.errors.graphqlError) {
        generalErrorToast();
      } else if (error.errors.validationError) {
        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: t("c.reactivation.customize_modal.uploader_error"),
        });
      }
    }
  }

  return null;
}
