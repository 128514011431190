









































import { t } from "@/i18n";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomCheckbox,
  AtomModal,
  OrgFormInput,
} from "@/v2/design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, watch } from "@vue/composition-api";
import { IReactivationEmailTestEmailModalProps } from "./props";
import { isValidEmail } from "@/v2/util/validator/email";
import { debounce } from "@/v2/util/debounce";
import {
  reactivationEmailBusinessId,
  reactivationEmailTestModalShowState,
} from "../state";
import { createReactivationTestEmail } from "@/v2/repo/create-reactivation-test-email";
import { Toast } from "@/design-system";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { reactivationEmailMethodTrack } from "../method/track";
import { EventEnum } from "@/v2/enum/events";

const css = bemBuilder("reactivation-email-test-email-modal");

export default defineComponent<IReactivationEmailTestEmailModalProps>({
  name: "ReactivationEmailTestEmailModal",
  components: {
    AtomModal,
    AtomCheckbox,
    AtomButton,
    OrgFormInput,
  },
  setup(_, ctx) {
    const checkboxCheckedState = ref(false);
    const emailValueState = ref("");
    const emailInputHasErrorState = ref(false);
    const disableSendBtnState = ref(true);

    const deboucedValidateEmail = debounce(() => {
      if (!emailValueState.value.trim()) {
        emailInputHasErrorState.value = false;
        disableSendBtnState.value = true;
        return;
      }

      if (isValidEmail(emailValueState.value)) {
        emailInputHasErrorState.value = false;
        disableSendBtnState.value = false;
        return;
      }

      emailInputHasErrorState.value = true;
    }, 500);

    watch(
      () => emailValueState.value,
      () => {
        disableSendBtnState.value = true;
        deboucedValidateEmail();
      }
    );

    async function onClickSendTest(): Promise<void> {
      reactivationEmailMethodTrack(ctx, EventEnum.CLICKED_ON_BUTTON, {
        label: "Send test",
      });

      const response = await createReactivationTestEmail({
        businessId: reactivationEmailBusinessId.value,
        email: emailValueState.value,
        testSubject: checkboxCheckedState.value,
      });

      reactivationEmailTestModalShowState.value = false;

      if (!response.sendTestEmailForCampaign) {
        generalErrorToast();
        return;
      }

      new Toast().create({
        type: "success",
        title: t("c.reactivation.toast.test_success_toast_title"),
        text: t("c.reactivation.toast.test_success_toast_desc", {
          email: emailValueState.value,
        }),
      });
    }

    return {
      css,
      t,
      reactivationEmailTestModalShowState,
      checkboxCheckedState,
      emailInputHasErrorState,
      disableSendBtnState,
      onClickSendTest,
      emailValueState,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
