import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";

export interface ICreateReactivationEmailCoverImageRequest {
  businessId: string;
  campaignCoverImage: File;
}

export async function createReactivationEmailCoverImage(
  request: ICreateReactivationEmailCoverImageRequest
): Promise<Response> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        businessId: request.businessId,
        campaignCoverImage: request.campaignCoverImage,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new CreateReactivationEmailCoverImageError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new CreateReactivationEmailCoverImageError(errors);
  }

  return response.data;
}

export class CreateReactivationEmailCoverImageError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: CreateReactivationEmailCoverImage");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {
  campaignCoverImage: boolean;
  max: boolean;
};

interface Response {
  uploadCampaignEmailCoverImage: string | null;
}
