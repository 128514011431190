/* eslint-disable */
import { Toast } from "@/design-system";
import { updateReactivationCampaignSettings } from "@/v2/repo/update-reactivation-campaign-settings";
import {
  reactivationEmailBusinessId,
  reactivationEmailBusinessTimezone,
  reactivationEmailIsDiscountValidState,
  reactivationEmailLoadingCampaignState,
  reactivationEmailSettingsState,
  reactivationEmailToggleState,
} from "../state";
import { t } from "@/i18n";
import { reactivationEmailMethodLoadCampaign } from "./load-campaign";
import { generalErrorToast } from "@/v2/util/general-error-toast";
// @ts-ignore
import { dateTz } from "@/utils/filters/date.js";
import { ReactivationPeriodOfDayEnum } from '@/v2/enum/reactivation/period-of-day.enum';

export async function reactivationEmailMethodSaveCampaignSettings(): Promise<
  void
> {
  try {

    const isDiscountValid = reactivationEmailIsDiscountValidState.value
    const response = await updateReactivationCampaignSettings({
      businessId: reactivationEmailBusinessId.value,
      campaignSettings: {
        frequencyDays: Number(reactivationEmailSettingsState.frequency),
        periodOfDay: reactivationEmailSettingsState.sendingTime,
        discountId: isDiscountValid ? reactivationEmailSettingsState.discountId : null
      }
    });

    let timeNow = parseInt(dateTz(new Date(), reactivationEmailBusinessTimezone.value, "HHmmss"), 10);
    let sendingTimeTranslationKey = "";

    if (reactivationEmailSettingsState.sendingTime === ReactivationPeriodOfDayEnum.MORNING) {
      // Is sooner than 10am?
      sendingTimeTranslationKey = timeNow < 100000 ? "today" : "tomorrow"
    }

    if (reactivationEmailSettingsState.sendingTime === ReactivationPeriodOfDayEnum.AFTERNOON) {
      // Is sooner than 5pm?
      sendingTimeTranslationKey = timeNow < 170000 ? "today" : "tomorrow"
    }

    const isCampaignActive = reactivationEmailToggleState.value;

    if (response.updateCampaignSettings) {
      new Toast().create({
        type: "success",
        title: isCampaignActive
        ? t("c.reactivation.toast.setting_save_title")
        : t("c.reactivation.toast.changes_saved"),
        text: isCampaignActive
        ? `${t("c.reactivation.toast.setting_save_desc")} ${t("c.reactivation.toast."+sendingTimeTranslationKey)}`
        : `${t("c.reactivation.toast.saved_inactive")} ${t("c.reactivation.toast."+sendingTimeTranslationKey)}`
      });
    }

    reactivationEmailLoadingCampaignState.value = true;
    await reactivationEmailMethodLoadCampaign(
      reactivationEmailBusinessId.value
    );
    reactivationEmailLoadingCampaignState.value = false;
  } catch (error) {
    generalErrorToast();
  }
}
