import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";

export interface IUpdateReactivationEmailTemplateRequest {
  businessId: string;
  templateSettings: {
    emailSubject: string;
    messageHeadline: string;
    messageBody: string;
    buttonText: string;
    offerHeadline: string | null;
    offerBody: string | null;
    coverImage: string | null;
  };
}

export async function updateReactivationEmailTemplate(
  request: IUpdateReactivationEmailTemplateRequest
): Promise<Response> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        businessId: request.businessId,
        campaignEmailAttributes: request.templateSettings,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new UpdateReactivationEmailTemplateError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new UpdateReactivationEmailTemplateError(errors);
  }

  return response.data;
}

export class UpdateReactivationEmailTemplateError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: UpdateReactivationEmailTemplate");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};

interface Response {
  updateCampaignEmailTemplate: boolean | null;
}
