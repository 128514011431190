




























































































































/* eslint-disable */
import { t } from "@/i18n";
import {
  AtomButton,
  AtomButtonTypeEnum,
  AtomModal,
  AtomText,
  AtomTextSizeEnum,
  OrgFormImageDragSelector,
  OrgFormInput,
  OrgFormTextarea
} from "@/v2/design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "@vue/composition-api";
import { component } from "vue/types/umd";
import { IReactivationEmailCustomizeModalProps } from "./props";
import { ReactivationEmailPreview } from "../preview";
import {
  reactivationEmailBusinessId,
  reactivationEmailBusinessName,
  reactivationEmailModalShowState,
  reactivationEmailState
} from "../state";
import { updateReactivationEmailTemplate, UpdateReactivationEmailTemplateError } from '@/v2/repo/update-reactivation-email-template';
import { Toast } from '@/design-system';
import { createReactivationEmailCoverImage, CreateReactivationEmailCoverImageError } from '@/v2/repo/create-reactivation-email-cover-image';
import { replaceReactiveObject } from '@/v2/util/replace-reactive-object';
import { imageURLBuilder } from '@/v2/util/image-url-builder';
import { reactivationEmailMethodSaveEmailTempalate } from '../method/save-email-template';
import { reactivationEmailCreateEmailCoverImage } from '../method';
import { reactivationEmailMethodTrack } from '../method/track';
import { EventEnum } from '@/v2/enum/events';
import { imageValidationErrorToast } from '@/v2/util/image-validation-error-toast';
import { validateImage, ValidateImageEnum } from '@/v2/util/validate-image';
import { generalErrorToast } from '@/v2/util/general-error-toast';
import { OrgImageCropper } from '@/v2/design-system/org/image-cropper';

const css = bemBuilder("reactivation-email-customize-modal");

export default defineComponent<IReactivationEmailCustomizeModalProps>({
  name: "ReactivationEmailCustomizeModal",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
    OrgFormImageDragSelector,
    OrgFormInput,
    OrgFormTextarea,
    ReactivationEmailPreview,
    OrgImageCropper
  },
  setup(props, ctx) {

    const editingEmailState: typeof reactivationEmailState = reactive(JSON.parse(JSON.stringify(reactivationEmailState)));
    const imageCropperDataURLState = ref('');
    const submitLoadingState = ref(false);
    const isImageUploadingState = ref(false);

    function errorMsgFor(fieldValue: string, fieldLabel: string) {
      if (!fieldValue) {
        return t("c.reactivation.customize_modal.required_error", {
          field: t(`c.reactivation.customize_modal.${fieldLabel}`)
        });
      }
    }

    const formInvalid = computed(() =>
      [
        editingEmailState.subject,
        editingEmailState.content,
        editingEmailState.headline,
        editingEmailState.buttonText
      ].includes("")
    );

    async function onClickSave(): Promise<void> {
      submitLoadingState.value = true;
      await reactivationEmailMethodSaveEmailTempalate(editingEmailState);
      submitLoadingState.value = false;

      reactivationEmailMethodTrack(
        ctx,
        EventEnum.CLICKED_ON_BUTTON,
        { label: "Save email" }
      );
    }

    async function onSelectImage(image: File) {

      const imageValidationConfig = {
        maxFileSizeMB: 5,
        minWidth: 750,
        maxHeight: 1500
      };

      const imageValidation = await validateImage(image, imageValidationConfig);

      imageValidationErrorToast(imageValidation, imageValidationConfig);

      if (imageValidation !== ValidateImageEnum.VALID) return;

      imageCropperDataURLState.value = URL.createObjectURL(image);
    }

    async function uploadCroppedImage(image: File) {
      isImageUploadingState.value = true;
      const response = await reactivationEmailCreateEmailCoverImage(image);

      if (response) {
        editingEmailState.previewImageURL = response;
      }

      isImageUploadingState.value = false;
    }

    function onRemoveImage(): void {
      editingEmailState.previewImageURL = null;
    }

    return {
      t,
      css,
      errorMsgFor,
      formInvalid,
      onRemoveImage,
      reactivationEmailBusinessName,
      reactivationEmailModalShowState,
      isImageUploadingState,
      imageCropperDataURLState,
      uploadCroppedImage,
      submitLoadingState,
      editingEmailState,
      imageURLBuilder,
      onClickSave,
      onSelectImage,
      AtomButtonTypeEnum,
      AtomTextSizeEnum
    };
  }
});
