import { reactive, ref } from "@vue/composition-api";
//---- TODO: Remove on Vue 3 migrate [VUE_3_MIGRATE]
import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
Vue.use(VueCompositionApi);
// -----

import { ReactivationPeriodOfDayEnum } from "@/v2/enum/reactivation/period-of-day.enum";
import { ReactivationFrequencyDaysEnum } from "@/v2/enum/reactivation/frequency-days.enum";
import { t } from "@/i18n";

interface IReactivationEmailSettingsState {
  frequency: ReactivationFrequencyDaysEnum;
  sendingTime: ReactivationPeriodOfDayEnum;
  discountId: string | null;
  discountCode: string | null;
  selectedDiscountLabel: string;
}

interface IReactivationEmailState {
  subject: string | null;
  headline: string | null;
  content: string | null;
  offerHeadline: string | null;
  offerContent: string | null;
  buttonText: string | null;
  previewImageURL: string | null;
  logoURL: string | null;
  orderingURL: string | null;
  color: string | null;
}

export const reactivationEmailSettingsDefault = {
  frequency: 14,
  sendingTime: ReactivationPeriodOfDayEnum.MORNING,
  discountId: "",
  discountCode: "",
  selectedDiscountLabel: "",
};

export const reactivationEmailStateDefault: IReactivationEmailState = {
  subject: t(`c.reactivation.customize_modal.email.subject_default`),
  headline: t(`c.reactivation.customize_modal.email.message_headline_default`),
  content: t(`c.reactivation.customize_modal.email.message_content_default`),
  offerHeadline: t(
    `c.reactivation.customize_modal.email.offer_headline_default`
  ),
  offerContent: null,
  buttonText: t(`c.reactivation.customize_modal.email.button_text_default`),
  previewImageURL: null,
  logoURL: null,
  orderingURL: null,
  color: "",
};

export const reactivationEmailState = reactive<IReactivationEmailState>({
  ...reactivationEmailStateDefault,
});
export const reactivationEmailSettingsState =
  reactive<IReactivationEmailSettingsState>({
    ...reactivationEmailSettingsDefault,
  });

export const reactivationEmailModalShowState = ref(false);
export const reactivationEmailToggleState = ref(false);
export const reactivationEmailBusinessId = ref("");
export const reactivationEmailBusinessTimezone = ref("");
export const reactivationEmailBusinessName = ref("");
export const reactivationEmailIsNewBusinessState = ref(true);
export const reactivationEmailIsDiscountValidState = ref(true);
export const reactivationEmailSentCountState = ref(0);
export const reactivationEmailLoadingCampaignState = ref(true);
export const reactivationEmailTestModalShowState = ref(true);
