import { replaceReactiveObject } from "@/v2/util/replace-reactive-object";
import {
  reactivationEmailModalShowState,
  reactivationEmailToggleState,
  reactivationEmailState,
  reactivationEmailStateDefault,
  reactivationEmailSettingsState,
  reactivationEmailSettingsDefault,
  reactivationEmailIsNewBusinessState,
  reactivationEmailIsDiscountValidState,
  reactivationEmailSentCountState,
  reactivationEmailLoadingCampaignState,
  reactivationEmailBusinessTimezone,
  reactivationEmailTestModalShowState,
} from "../state";

export function reactivationEmailMethodInitializeState(): void {
  reactivationEmailModalShowState.value = false;
  reactivationEmailToggleState.value = false;
  reactivationEmailIsNewBusinessState.value = true;
  reactivationEmailBusinessTimezone.value = "";
  reactivationEmailIsDiscountValidState.value = true;
  reactivationEmailSentCountState.value = 0;
  reactivationEmailLoadingCampaignState.value = true;
  reactivationEmailTestModalShowState.value = false;

  replaceReactiveObject(reactivationEmailState, {
    ...reactivationEmailStateDefault,
  });
  replaceReactiveObject(reactivationEmailSettingsState, {
    ...reactivationEmailSettingsDefault,
  });
}
