

































































import { t } from "@/i18n";
import { AtomText, AtomTextSizeEnum, MolInfoBox } from "@/v2/design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";
import { ReactivationEmailCustomizeModal } from "../customize-modal";
import { ReactivationEmailPreview } from "../preview";
import { ReactivationEmailSettingForm } from "../setting-form";
import {
  reactivationEmailBusinessId,
  reactivationEmailBusinessName,
  reactivationEmailIsNewBusinessState,
  reactivationEmailLoadingCampaignState,
  reactivationEmailModalShowState,
  reactivationEmailSettingsState,
  reactivationEmailState,
  reactivationEmailTestModalShowState,
  reactivationEmailToggleState,
} from "../state";
import { ReactivationEmailToggle } from "../toggle";
import { imageURLBuilder } from "@/v2/util/image-url-builder";
import {
  reactivationEmailMethodInitializeState,
  reactivationEmailMethodLoadCampaign,
} from "../method";
import { reactivationEmailMethodTrack } from "../method/track";
import { EventEnum } from "@/v2/enum/events";
import { ReactivationEmailTestEmailModal } from "../test-email-modal";
import { AtomLoading } from "@/v2/new-design-system";

const css = bemBuilder("reactivation-email-main");

export default defineComponent({
  name: "ReactivationEmailMain",
  components: {
    ReactivationEmailSettingForm,
    ReactivationEmailPreview,
    ReactivationEmailCustomizeModal,
    ReactivationEmailToggle,
    ReactivationEmailTestEmailModal,
    MolInfoBox,
    AtomText,
    AtomLoading,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(_, ctx) {
    reactivationEmailMethodInitializeState();
    reactivationEmailBusinessId.value = ctx.root.$route?.params?.businessId;
    const loadingState = reactivationEmailLoadingCampaignState;
    const settingsState = reactivationEmailSettingsState;
    const emailState = reactivationEmailState;
    const modalShowState = reactivationEmailModalShowState;
    const isNewBusinessState = reactivationEmailIsNewBusinessState;

    onMounted(async () => {
      loadingState.value = true;

      const loadSuccess = await reactivationEmailMethodLoadCampaign(
        reactivationEmailBusinessId.value
      );

      reactivationEmailMethodTrack(ctx, EventEnum.EMAIL_REACTIVATION_START, {
        isActiveCampaign: reactivationEmailToggleState.value,
      });

      loadingState.value = false;
    });

    watch(
      () => ctx.root.$route,
      async (routeChange) => {
        reactivationEmailBusinessId.value = routeChange.params.businessId;
        reactivationEmailMethodInitializeState();
        loadingState.value = true;
        const loadSuccess = await reactivationEmailMethodLoadCampaign(
          routeChange.params.businessId
        );

        reactivationEmailMethodTrack(ctx, EventEnum.EMAIL_REACTIVATION_START, {
          isActiveCampaign: reactivationEmailToggleState.value,
        });
        loadingState.value = false;
      }
    );

    // TODO: Consider moving this logic into a composable to reuse
    const infoBoxSeenState = ref(false);

    const infoBoxSeen = computed<boolean>({
      set: (value: boolean) => {
        localStorage.setItem("reactivation-info-seen", value.toString());
        infoBoxSeenState.value = value;
      },
      get: () => {
        if (localStorage.getItem("reactivation-info-seen")) {
          return true;
        }

        return infoBoxSeenState.value;
      },
    });

    function onClickCloseInfobox() {
      reactivationEmailMethodTrack(ctx, EventEnum.CLICKED_ON_BUTTON, {
        label: "Close info box",
      });

      infoBoxSeen.value = true;
    }

    function onClickInfoBoxCTA() {
      reactivationEmailMethodTrack(ctx, EventEnum.CLICKED_ON_BUTTON, {
        label: "Find out more",
      });

      window.open(
        "http://docs.chatfood.io/en/articles/4547076-reactivating-your-customers",
        "_blank",
        "noopener, noreferrer"
      );
    }

    function onClickSendTestBtn() {
      reactivationEmailTestModalShowState.value = true;
    }

    return {
      css,
      t,
      reactivationEmailBusinessId,
      emailState,
      loadingState,
      isNewBusinessState,
      onClickCloseInfobox,
      onClickSendTestBtn,
      reactivationEmailBusinessName,
      reactivationEmailTestModalShowState,
      onClickInfoBoxCTA,
      modalShowState,
      imageURLBuilder,
      infoBoxSeen,
      AtomTextSizeEnum,
    };
  },
});
