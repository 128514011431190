import { graphql } from "../graphql";
import query from "./query.gql";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";
import { ReactivationFrequencyDaysEnum } from "@/v2/enum/reactivation/frequency-days.enum";
import { ReactivationPeriodOfDayEnum } from "@/v2/enum/reactivation/period-of-day.enum";
import { OrderingServiceEnum } from "@/v2/enum";

export async function fetchReactivationCampaign(
  businessId: string
): Promise<IFetchReactivationCampaignResponse> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new FetchReactivationCampaignError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new FetchReactivationCampaignError(errors);
  }

  return {
    businessUrl: response.data.business.url,
    businessColor: response.data.business.colorSet?.colorPrimary || null,
    businessLogo: response.data.business.logo,
    businessTimezone: response.data.business.timezone,
    businessName: response.data.business.name,
    campaign: response.data.business.campaign,
  };
}

export class FetchReactivationCampaignError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: FetchReactivationCampaign");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};

export interface IFetchReactivationCampaignResponse {
  businessUrl: string;
  businessColor: string | null;
  businessLogo: string | null;
  businessTimezone: string;
  businessName: string;
  campaign: {
    frequencyDays: ReactivationFrequencyDaysEnum;
    isActive: boolean;
    periodOfDay: ReactivationPeriodOfDayEnum;
    emailCount: number;
    discount: {
      id: string;
      name: string;
      code: string;
      status: boolean;
      description: string;
      endsAt: string | null;
      orderingServices: Array<OrderingServiceEnum>;
    } | null;
    emailTemplate: {
      coverImage: string;
      emailSubject: string;
      messageHeadline: string;
      messageBody: string;
      offerHeadline: string;
      offerBody: string;
      buttonText: string;
    } | null;
  } | null;
}
