


















































































import { computed, defineComponent } from "@vue/composition-api";
import { IReactivationEmailPreviewProps } from "./props";
import { bemBuilder } from "@/v2/util/bem-builder";
import { boolean, func, string } from "@/v2/util/prop/type";
import { Button } from "@/design-system";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomText,
  AtomTextSizeEnum,
} from "@/v2/design-system";
import { t } from "@/i18n";
import { imageURLBuilder } from "@/v2/util/image-url-builder";

const css = bemBuilder("reactivation-email-preview");

export default defineComponent<IReactivationEmailPreviewProps>({
  name: "ReactivationEmailPreview",
  components: {
    Button,
    AtomText,
    AtomButton,
  },
  props: {
    showSendTestBtn: boolean().use(),
    onClickSendTestBtn: func().use(),
    logoUrl: string().isRequired().use(),
    imageUrl: string().use(),
    headline: string().isRequired().use(),
    content: string().isRequired().use(),
    offerHeadline: string().use(),
    offerContent: string().use(),
    buttonText: string().isRequired().use(),
    color: string().use(),
    orderingURL: string().isRequired().use(),
    businessName: string().isRequired().use(),
  },
  setup(props) {
    const processedContent = computed(
      () => props.content?.replace(/\n/g, "<br />") || null
    );

    function onClickOrderButton() {
      window.open(props.orderingURL, "_blank", "noopener, noreferrer");
    }
    const logo = computed(() => imageURLBuilder(props.logoUrl, 100, 100));

    return {
      css,
      t,
      processedContent,
      onClickOrderButton,
      AtomTextSizeEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      logo,
    };
  },
});
