import { OrderingServiceEnum } from "@/v2/enum";
import { reactivationEmailState } from "../state";

export function reactivationEmailSetOfferContent(
  discount: {
    id: string;
    name: string;
    code: string;
    status: boolean;
    description: string;
    endsAt: string | null;
    orderingServices: Array<OrderingServiceEnum>;
  } | null
): void {
  if (discount) {
    let date: Date | null = null;
    let day: string = "";
    let month: string = "";

    if (discount?.endsAt) {
      date = new Date(discount?.endsAt);
      day = ("0" + date.getDate()).slice(-2);
      month = ("0" + (date.getMonth() + 1)).slice(-2);
    }

    const description = discount.description;
    reactivationEmailState.offerContent = `
    Use the code <strong>${discount?.code}</strong> to
    ${description[0].toLowerCase() + description.slice(1)}${
      date ? " until " + day + "/" + month : ""
    }`;
  } else {
    reactivationEmailState.offerContent = "";
  }
}
