import { OrderingServiceEnum } from "@/v2/enum";
import { fetchReactivationCampaign } from "@/v2/repo/fetch-reactivation-campaign";
import {
  reactivationEmailState,
  reactivationEmailIsNewBusinessState,
  reactivationEmailSettingsState,
  reactivationEmailToggleState,
  reactivationEmailIsDiscountValidState,
  reactivationEmailSentCountState,
  reactivationEmailBusinessTimezone,
  reactivationEmailBusinessName,
} from "../state";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { reactivationEmailMethodIsDiscountValid } from "./is-discount-valid";
import { reactivationEmailSetOfferContent } from "./set-offer-content";
import { t } from "@/i18n";

export async function reactivationEmailMethodLoadCampaign(
  businessId: string
): Promise<boolean> {
  try {
    const response = await fetchReactivationCampaign(businessId);

    if (response) {
      // Setting common state regardless of campaign exist or not
      reactivationEmailState.logoURL = response.businessLogo;
      reactivationEmailBusinessTimezone.value = response.businessTimezone;
      reactivationEmailState.color = response.businessColor;
      reactivationEmailBusinessName.value = response.businessName;

      //@ts-ignore
      let orderingURL = `${process.env.VUE_APP_WEBVIEW_URL}/${response.businessUrl}`;
      const defaultType = discountCodeDefaultType(
        response.campaign?.discount?.orderingServices || []
      );

      if (response.campaign?.discount && defaultType) {
        orderingURL += `/${defaultType.toLowerCase()}?v=${
          response.campaign.discount.code
        }`;
      }

      reactivationEmailState.orderingURL = orderingURL;
    }

    if (response.campaign) {
      if (response.campaign.discount) {
        reactivationEmailIsDiscountValidState.value =
          reactivationEmailMethodIsDiscountValid(
            response.campaign.discount?.status,
            response.campaign.discount?.endsAt
          );
      } else {
        reactivationEmailIsDiscountValidState.value = true;
      }

      // Setting state when campaign exists
      reactivationEmailSentCountState.value = response.campaign.emailCount;
      reactivationEmailIsNewBusinessState.value = false;
      reactivationEmailSettingsState.frequency =
        response.campaign.frequencyDays;
      reactivationEmailSettingsState.sendingTime =
        response.campaign.periodOfDay;
      reactivationEmailSettingsState.discountId =
        response.campaign.discount?.id || "";
      reactivationEmailSettingsState.discountCode =
        response.campaign.discount?.code || null;
      reactivationEmailSettingsState.selectedDiscountLabel = response.campaign
        .discount?.id
        ? `${response.campaign.discount?.code} · ${response.campaign.discount?.name}`
        : "";
      reactivationEmailToggleState.value = response.campaign.isActive;

      if (response.campaign.emailTemplate) {
        reactivationEmailState.logoURL = response.businessLogo;
        reactivationEmailState.previewImageURL =
          response.campaign.emailTemplate.coverImage;
        reactivationEmailState.subject =
          response.campaign.emailTemplate.emailSubject;
        reactivationEmailState.headline =
          response.campaign.emailTemplate.messageHeadline;
        reactivationEmailState.content =
          response.campaign.emailTemplate.messageBody;
        reactivationEmailState.offerHeadline =
          response.campaign.emailTemplate.offerHeadline;
        reactivationEmailState.buttonText =
          response.campaign.emailTemplate.buttonText;

        reactivationEmailSetOfferContent(response.campaign.discount);

        return true;
      }

      reactivationEmailSetOfferContent(response.campaign.discount);
      const templateBase = "c.reactivation.customize_modal.email";
      reactivationEmailState.content = response.campaign.discount
        ? t(`${templateBase}.message_content_default_discount`)
        : t(`${templateBase}.message_content_default`);
    }
  } catch (error) {
    generalErrorToast();
  }

  return false;
}

function discountCodeDefaultType(
  discountOrderingServices: Array<OrderingServiceEnum>
): OrderingServiceEnum | string {
  const orderingServicesCount = discountOrderingServices?.length;

  if (orderingServicesCount === 0) {
    return "";
  }

  if (orderingServicesCount === 1) {
    if (discountOrderingServices[0] === OrderingServiceEnum.DINE_IN) {
      return "";
    }
    return discountOrderingServices[0];
  }

  if (
    discountOrderingServices &&
    discountOrderingServices.includes(OrderingServiceEnum.DELIVERY)
  ) {
    return OrderingServiceEnum.DELIVERY;
  }

  return OrderingServiceEnum.PICKUP;
}
