








































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { IReactivationEmailToggleProps } from "./props";
import { t, tc } from "@/i18n";
import { number } from "@/v2/util/prop/type";
import { AtomSwitcher, AtomText, AtomTextSizeEnum } from "@/v2/design-system";
import {
  reactivationEmailBusinessId,
  reactivationEmailIsDiscountValidState,
  reactivationEmailSentCountState,
  reactivationEmailSettingsDefault,
  reactivationEmailSettingsState,
  reactivationEmailState,
  reactivationEmailToggleState,
} from "../state";
import {
  updateReactivationCampaignStatus,
  UpdateReactivationCampaignStatusError,
} from "@/v2/repo/update-reactivation-campaign-status";
import { Toast } from "@/design-system";
import { reactivationEmailMethodSaveCampaignActiveState } from "../method";
import { reactivationEmailMethodTrack } from "../method/track";
import { EventEnum } from "@/v2/enum/events";
import { reactivationEmailSetOfferContent } from "../method/set-offer-content";
const css = bemBuilder("reactivation-email-toggle");

export default defineComponent<IReactivationEmailToggleProps>({
  name: "ReactivationEmailToggle",
  components: {
    AtomSwitcher,
    AtomText,
  },
  props: {
    emailCount: number().setDefault(0).use(),
    days: number().setDefault(0).use(),
  },
  setup(props, ctx) {
    const toggleState = reactivationEmailToggleState;

    async function onChangeToggle() {
      toggleState.value = !toggleState.value;

      const eventName = toggleState.value
        ? EventEnum.EMAIL_REACTIVATION_ACTIVATE
        : EventEnum.EMAIL_REACTIVATION_DEACTIVATE;

      reactivationEmailMethodTrack(ctx, eventName, {
        discountCode: reactivationEmailSettingsState.discountCode,
        discountApplied: Boolean(reactivationEmailSettingsState.discountCode),
        emailCustomizationApplied: Boolean(reactivationEmailState.subject),
      });

      reactivationEmailMethodSaveCampaignActiveState(toggleState.value);
    }

    return {
      t,
      tc,
      css,
      reactivationEmailIsDiscountValidState,
      reactivationEmailSentCountState,
      onChangeToggle,
      toggleState,
      AtomTextSizeEnum,
    };
  },
});
