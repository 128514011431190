import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";
import { OrderingServiceEnum } from "@/v2/enum";

export async function fetchDiscount(
  businessId: string,
  discountId: string
): Promise<IFetchDiscountResponse> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
        id: discountId,
      },
    });

    return response.data.discount;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export interface IFetchDiscountResponse {
  id: string;
  name: string;
  code: string;
  visibility: string;
  type: string;
  amount: number;
  minimumOrderValue: number;
  maximumDiscount: number;
  segmentation: string;
  usesPerCustomer: number;
  startsAt: string;
  endsAt: string;
  totalUses: number;
  status: boolean;
  description: string;
  orderingServices: Array<OrderingServiceEnum>;
  items: Array<{ id: string }>;
  outlets: Array<{ id: string }>;
}
